@import '../theme.scss';

$headerPadding: 10px;
$footerHeight: 60px;

* {
    box-sizing: border-box;
}

p, h1, h2, h3, ul {
    margin: 0;
}

body {
    padding: 0;
    margin: 0;
    color: #4a4a4a;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5;
}

.main {
    width: 100%;
    margin-top: -60px;
    display: flex;
    flex-direction: column;
}

.overlay,
.background {
    min-width: 100vw;
    min-height: 100vh;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
}

.overlay {
    background-color: $dark-extra-transparent;
    z-index: -2;
}
  
.background {
    background-image: url("/hangar.jpg");
    background-size: cover;
    background-position-y: bottom;
    z-index: -3;
}

.appHeader {
    display: flex;
    justify-content: space-between;
    padding: $headerPadding;
    width: 100%;
    height: $headerHeight;
    box-sizing: border-box;
}

.menu {
    z-index: 1;
    button {
        padding: 5px 10px;
    }
}

.appFooter {
    font-size: small;
    font-style: italic;
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 0px;
    z-index: -1; // else it hides last cars selection buttons
    color: $text-medium-dark;
}


.appContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 100%;
    font-family: Gill Sans,sans-serif;
}


@media (orientation: portrait) {
    body {
        height: auto;
        max-height: 100vh;
        overflow-y: auto;
    }

    .appContainer {
        height: auto;
    }
    .overlay,
    .background {
        position: fixed;
    }
}

/** Bulma styles */
.icon-button {
    font-size: 20px;
    background-color: rgba(80,80,80,.5);
    color: #c8c8c8;
    width: auto;
    height: auto;
    padding: 10px;
    cursor: pointer;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Modal */
.modal {
    align-items: center;
    display: none;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    position: fixed;
    z-index: 40;
}

.modal-background {
    background-color: hsla(0,0%,4%,.86);
}

.modal-card, .modal-content {
    margin: 0 20px;
    max-height: calc(100vh - 160px);
    overflow: auto;
    position: relative;
    width: 100%;
}

.modal.is-active {
    display: flex;
}

.modal, .modal-background {
    background-color: hsla(0,0%,4%,.86);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.backdrop {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

@media screen and (min-width: 769px) {
    .modal-card, .modal-content {
      margin: 0 auto;
      max-height: calc(100vh - 40px);
      width: 640px;
    }
}

/* Input */
.input::placeholder, .select select::placeholder, .textarea::placeholder {
    color: rgba(54,54,54,.3);
}

.input, .textarea {
    box-shadow: inset 0 .0625em .125em hsla(0,0%,4%,.05);
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
}

.input, .select select, .textarea {
    background-color: #fff;
    border-color: #dbdbdb;
    border-radius: 4px;
    color: #363636;
}

.button, .input, .select select, .textarea {
    align-items: center;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: none;
    display: inline-flex;
    font-size: 1rem;
    height: 2.5em;
    justify-content: flex-start;
    line-height: 1.5;
    padding: calc(.5em - 1px) calc(.75em - 1px);
    position: relative;
    vertical-align: top;
}

.label {
  display: block;
  font-size: 1rem;
  font-weight: 700;
}

.label:not(:last-child) {
    margin-bottom: .5em;
}

/* Colors */
.is-primary.input, .is-primary.textarea {
    border-color: #00d1b2;
}

.has-text-light {
    color: #f5f5f5 !important;
}

/* Badge */
.badge {
    font-family: Segoe UI Light;
    text-align: center;
    border: 3px solid #a1a1a1;
    background-color: #141414;
    color: #e1e1e1;
}
