$text-extra-bright: white;
$text-bright: #e1e1e1;
$text-medium: #a1a1a1;
$text-medium-dark: gray;
$text-dark: #333333;
$element-hover: rgba(50, 50, 50, 0.6);
$element-selected: rgba(80, 80, 80, 0.5);
$element-hover-bright: rgb(155, 155, 155);
$element-selected-bright: rgb(200, 200, 200);
$dark-extra-transparent: rgba(20, 20, 20, 0.4);
$dark-transparent: rgba(20, 20, 20, 0.8);
$dark-opaque: rgb(20, 20, 20);

$headerHeight: 60px;
